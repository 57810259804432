import resume from "../assets/pdf/AhmedRajaCV.pdf";

export const headerData = {
  name: "Ahmed Raja",
  title: "Software Devloper",
  desciption:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact. With experince in both  ",
  image:
    "https://firebasestorage.googleapis.com/v0/b/personal-portfolio-71fa2.appspot.com/o/WhatsApp%20Image%202021-12-10%20at%2011.40.29%20(3).jpeg?alt=media&token=690e4ebb-db3a-402f-aee7-68b4332060c5",
  resumePdf: resume,
};
