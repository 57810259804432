export const experienceData = [
  {
    id: 1,
    company: "Amazon",
    jobtitle: "L4 Software Development Engineer",
    startYear: "2022 Sep",
    endYear: "Current",
  },{
    id: 2,
    company: "IX Online",
    jobtitle: "Junior Full Stack Developer",
    startYear: "2021 Dec",
    endYear: "2022 Aug",
  },
  {
    id: 3,
    company: "University of Kwa-Zulu Natal",
    jobtitle: "Post Graduate Assistant",
    startYear: "2021 Mar",
    endYear: "2021 Nov",
  },
  {
    id: 4,
    company: "Amazon",
    jobtitle: "Intern Software Development Engineer",
    startYear: "2021 Jan",
    endYear: "2021 Mar",
  },
  {
    id: 5,
    company: "University of Kwa-Zulu Natal",
    jobtitle: "Post Graduate Assistant",
    startYear: "2020 Jan",
    endYear: "2020 Dec",
  },
];
