export const socialsData = {
  github: "https://github.com/ahmedraja26",
  // facebook: "https://www.facebook.com/",
  linkedIn: "https://www.linkedin.com/in/ahmed-raja-7b2501137/",
  //instagram: "https://www.instagram.com/",
  //codepen: "https://codepen.io/",
  twitter: "https://twitter.com/ahmed___raja",
  //reddit: "https://www.reddit.com/user/",
  //blogger: "https://www.blogger.com/",
  //medium: "https://medium.com/@",
  //stackOverflow: "https://stackoverflow.com/users/",
  //gitlab: "https://gitlab.com/",
  youtube: "https://www.youtube.com/channel/UCWqAf9jC5E99YfmVCknNHpw",
};
