import one from "../assets/svg/projects/one.svg";
import python from "../assets/svg/projects/python.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "The Complete Python Pro Bootcamp",
    projectDesc:
      "This project aims to Master Python by building 100 projects in 100 days. Learn data science, automation, build websites, games and apps!",
    tags: ["Python", "Bootcamp"],
    code: "https://github.com/ahmedraja26/PythonChallenge",
    demo: "https://github.com/ahmedraja26/PythonChallenge",
    image: "https://1000logos.net/wp-content/uploads/2020/08/Python-Logo.png",
  },
  {
    id: 2,
    projectName: ".NET Core Microservices using RabbitMQ Messaging",
    projectDesc:
      "This project aims to learn to build .NET Core Microservices using RabbitMQ Messaging",
    tags: ["C#", "Microservices", "RabbitMQ"],
    code: "https://github.com/ahmedraja26/MicroRabbit",
    demo: "https://github.com/ahmedraja26/MicroRabbit",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtI0Jy-h50Fhi0nx2EE7Lb9qfiedMDWZJvfQ&usqp=CAU",
  },
  {
    id: 3,
    projectName: "React - The Complete Guide",
    projectDesc:
      "This project aims to Dive in and learn React.js from scratch!",
    tags: ["React", "CSS"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8wh_vrysQFY_0CkcD-e-lYPPHLvsTaXqIGA&usqp=CAU",
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
