export const educationData = [
  {
    id: 1,
    institution: "Orient Islamic School",
    course: "High School",
    startYear: "2008",
    endYear: "2014",
  },
  {
    id: 2,
    institution: "University of Kwa-Zulu Natal",
    course: "Bachelor of Science, Computer Science and Information Technology",
    startYear: "2015",
    endYear: "2018",
  },
  {
    id: 3,
    institution: "University of Kwa-Zulu Natal",
    course: "Bachelor of Commerce Honours, Information Systems",
    startYear: "2019",
    endYear: "2019",
  },
  {
    id: 4,
    institution: "University of Kwa-Zulu Natal",
    course: "Bachelor of Commerce Masters, Information Systems",
    startYear: "2020",
    endYear: "Current",
  },
];
