export const achievementData = {
  bio: "",
  achievements: [
    {
      id: 1,
      title: "AWS Intern Jam Winner",
      details:
        "Competed with fellow interns from across the globe to complete a series of real-world challenges using AWS",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:6777287661494251520/",
      date: "Feb, 2021",
      field: "AWS",
      image:
        "https://media-exp1.licdn.com/dms/image/C560BAQE_4UwvNNtNTQ/company-logo_200_200/0/1640205885683?e=2147483647&v=beta&t=A-9vpU-DpLR3lg6cYzLHyo0hzJNAYBoV9GI8EGbqJZM",
    },
    {
      id: 2,
      title: "Certificate of Merit",
      details:
        "Certificate of Merit for Mobile and Personal Information Systems",
      link: "",
      date: "Nov, 2019",
      field: "UKZN",
      image:
        "https://pbs.twimg.com/profile_images/892321106639585280/73fOI07J_400x400.jpg",
    },
  ],
};

// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
